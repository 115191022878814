// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-lessons-js": () => import("./../src/templates/lessons.js" /* webpackChunkName: "component---src-templates-lessons-js" */),
  "component---src-templates-lesson-js": () => import("./../src/templates/lesson.js" /* webpackChunkName: "component---src-templates-lesson-js" */),
  "component---src-templates-videos-js": () => import("./../src/templates/videos.js" /* webpackChunkName: "component---src-templates-videos-js" */),
  "component---src-templates-video-js": () => import("./../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */),
  "component---src-templates-index-js": () => import("./../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-articles-js": () => import("./../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-faqs-js": () => import("./../src/templates/faqs.js" /* webpackChunkName: "component---src-templates-faqs-js" */),
  "component---src-templates-faq-js": () => import("./../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-pages-training-js": () => import("./../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */)
}

